import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchLevels(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/levels', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchLevel(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/levels/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editLevel(ctx, { id, name, department, category}) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/levels/${id}`, { 'name': name, 'department': department, 'category': category })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addLevel(ctx, levelData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/levels', levelData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
