<template>
  <b-sidebar id="edit-level-sidebar" :visible="isEditLevelSidebarActive" bg-variant="white" sidebar-class="sidebar-lg"
    shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-edit-level-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Edit Level
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />

      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

          <!-- Levelname -->
          <validation-provider #default="validationContext" name="Level Name" rules="required">
            <b-form-group label="Level Name" label-for="levelname">
              <b-form-input id="levelname" v-model="levelData.name" :state="getValidationState(validationContext)"
                trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Level Department -->
          <validation-provider #default="validationContext" name="Level Department" rules="required">
            <b-form-group label="Level Department" label-for="department" :state="getValidationState(validationContext)">
              <v-select v-model="levelData.department" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="levelDepartments" :clearable="false" input-id="department" :reduce="level => level.value" />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Level Category -->
          <validation-provider #default="validationContext" name="Level Category" rules="required">
            <b-form-group label="Level Category" label-for="category" :state="getValidationState(validationContext)">
              <v-select v-model="levelData.category" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="levelCategories" :clearable="false" input-id="category" :reduce="category => category.value" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
              Save
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
              @click="hide">
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, watch, getCurrentInstance } from '@vue/composition-api'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// import levelTypes from '@/@fake-db/data/other/levelTypes'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const levelDepartments = [
  { label: 'Advisors', value: 'advisors' },
  { label: 'Implement', value: 'implement' },
  { label: 'Operations', value: 'operations' },
  { label: 'External', value: 'external' },
]

const levelCategories = [
  { label: 'AP/P', value: 'AP/P' },
  { label: 'PM/SPM', value: 'PM/SPM' },
  { label: 'Junior Staff', value: 'Junior Staff' },
  { label: 'External', value: 'External' },
]

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditLevelSidebarActive',
    event: 'update:is-edit-level-sidebar-active',
  },
  props: {
    isEditLevelSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    // levelOptions: {
    //   type: Array,
    //   required: true,
    // },
    item: {
      required: true,
    }
  },
  data() {
    return {
      required,
      alphaNum,
      levelDepartments,
      levelCategories,
    }
  },
  setup(props, { emit }) {
    const blankLevelData = ref({
      name: '',
      department: '',
      category: '', // Added category field
    })

    const levelData = ref({ ...blankLevelData.value })

    const resetLevelData = () => {
      levelData.value = { ...blankLevelData.value }
    }

    // Watch for changes to the sidebar active state
    watch(() => props.isEditLevelSidebarActive, (newVal) => {
      if (newVal && props.item) {
        // Populate the form fields when the sidebar is opened
        levelData.value.name = props.item.name
        levelData.value.department = props.item.department
        levelData.value.category = props.item.category // Added category field
      } 
      // else {
      //   resetLevelData()
      // }
    }, { immediate: true })


    const { proxy } = getCurrentInstance()

    const onSubmit = () => {
      store.dispatch('app-level/editLevel', { id: props.item._id, name: levelData.value.name, department: levelData.value.department, category: levelData.value.category }) // Added category field
        .then(() => {
          emit('refetch-data')
          emit('update:is-edit-level-sidebar-active', false)
          proxy.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Level Saved Successfully!',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch((err) => {
          const errorMessage = (err.response && err.response.data && err.response.data.error) || 'Something went wrong.'
          proxy.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              text: errorMessage,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetLevelData)

    return {
      levelData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#edit-level-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.status-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.status-dot--active {
  background-color: rgb(0, 165, 0);
}

.status-dot--inactive {
  background-color: rgba(255, 0, 0, 0.76);
}
</style>
