import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useLevelsList() {
  // Use toast
  const toast = useToast()

  const refLevelListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: "name", sortable: true },
    { key: "category", label: "Category", sortable: true }, // Added category column
    { key: "department", label: "Dept.", sortable: true },
    { key: "hasSelfEval", label: "Self Eval", sortable: true },
    { key: "hasType1", label: "Type1 Eval", sortable: true },
    { key: "hasType2", label: "Type2 Eval", sortable: true },
    // { key: 'description', sortable: true },
    // { key: 'comments', sortable: true },
    // {
    //   key: 'currentPlan',
    //   label: 'Plan',
    //   formatter: title,
    //   sortable: true,
    // },
    // { key: 'status', sortable: true },
    { key: "actions", label: "Edit" },
  ];
  const limit = ref(10)
  const totalLevels = ref(0)
  const page = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('updatedAt')
  const sortOrder = ref('desc')
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refLevelListTable.value ? refLevelListTable.value.localItems.length : 0
    return {
      from: limit.value * (page.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (page.value - 1) + localItemsCount,
      of: totalLevels.value,
    }
  })

  const refetchData = () => {
    refLevelListTable.value.refresh()
  }

  watch([page, limit, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchLevels = (ctx, callback) => {
    store
      .dispatch('app-level/fetchLevels', {
        q: searchQuery.value,
        limit: limit.value,
        page: page.value,
        sortBy: sortBy.value,
        sortOrder: sortOrder.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { levels, total } = response.data

        callback(levels)
        totalLevels.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching levels list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveLevelRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveLevelRoleIcon = role => {
    if (role === 'subscriber') return 'LevelIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'LevelIcon'
  }

  const resolveLevelStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  const resolveDepartmentVariant = department => {
    if (department === 'advisors') return 'light-info'
    if (department === 'implement') return 'light-warning'
    if (department === 'operations') return 'light-secondary'
    if (department === 'external') return 'light-danger'
    return 'primary'
  }

  return {
    fetchLevels,
    tableColumns,
    limit,
    page,
    totalLevels,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    sortOrder,
    refLevelListTable,

    resolveLevelRoleVariant,
    resolveLevelRoleIcon,
    resolveLevelStatusVariant,
    resolveDepartmentVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
