<template>
  <div>
    <levels-list-add-new :is-add-new-level-sidebar-active.sync="isAddNewLevelSidebarActive" :role-options="roleOptions"
      :plan-options="planOptions" :category-options="categoryOptions" @refetch-data="refetchData" />

    <levels-list-edit :is-edit-level-sidebar-active.sync="isEditLevelSidebarActive" :role-options="roleOptions"
      :plan-options="planOptions" :category-options="categoryOptions" :item="item" @refetch-data="refetchData" />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select v-model="limit" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
              <b-button variant="primary" @click="isAddNewLevelSidebarActive = true">
                <span class="text-nowrap">Add Level</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table ref="refLevelListTable" class="position-relative" :items="fetchLevels" responsive :fields="tableColumns"
        primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
        :sort-desc.sync="sortOrderSync">
        <template #table-busy>
          <div class="text-center my-3">
            <b-spinner style="width: 3rem; height: 3rem;" variant="primary" label="Loading..."></b-spinner>
          </div>
        </template>
        <!-- Column: Name -->
        <template #cell(name)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize"><b>{{ data.item.name }}</b></span>
          </div>
        </template>

        <!-- Column: Category -->
        <template #cell(category)="data">
          <div class="text-nowrap">
            <b-badge v-if="data.item.category"> 
              <span class="text-capitalize">{{ data.item.category }}</span>
            </b-badge>
            <b-badge v-else variant="light-dark" pill>
            <span>N/A</span>
          </b-badge>
          </div>
        </template>

        <!-- Column: Department -->
        <template #cell(department)="data">
          <div class="text-nowrap">
            <b-badge :variant="resolveDepartmentVariant(data.item.department)">
              <span class="text-capitalize">{{ data.item.department }}</span>
            </b-badge>
          </div>
        </template>

        <!-- Column: hasSelfEval -->
        <template #cell(hasSelfEval)="data">
          <span v-if="data.item.department.toLowerCase() != 'external'"
            :class="data.item.hasSelfEval ? 'status-dot status-dot--active' : 'status-dot status-dot--inactive'" ></span>
          <b-badge v-else variant="light-dark" pill>
            <span>N/A</span>
          </b-badge>
        </template>

        <!-- Column: hastype1 -->
        <template #cell(hasType1)="data">
          <span v-if="data.item.department.toLowerCase() != 'external'"
            :class="data.item.hasType1 ? 'status-dot status-dot--active' : 'status-dot status-dot--inactive'" 
            v-b-tooltip.hover.top="getTooltip(data.item.category, 'type1')"></span>
          <b-badge v-else v-b-tooltip.hover.top="getTooltip(data.item.category, 'type1')" variant="light-dark" pill>
            <span>N/A</span>
          </b-badge>
        </template>

        <!-- Column: hastype2 -->
        <template #cell(hasType2)="data">
          <span v-if="data.item.department.toLowerCase() != 'external'"
            :class="data.item.hasType2 ? 'status-dot status-dot--active' : 'status-dot status-dot--inactive'" 
            v-b-tooltip.hover.top="getTooltip(data.item.category, 'type2')"></span>
          <b-badge v-else v-b-tooltip.hover.top="getTooltip(data.item.category, 'type2')" variant="light-dark" pill>
            <span>N/A</span>
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <feather-icon v-if="data.item.department.toLowerCase() != 'external'" icon="EditIcon" class="edit-button"
            @click="editItem(data.item)" />
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
              entries</span>
          </b-col>

          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="page" :total-rows="totalLevels" :per-page="limit" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, computed, onMounted, getCurrentInstance } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useLevelsList from './useLevelsList'
import levelStoreModule from '../levelStoreModule'
import LevelsListAddNew from './LevelsListAddNew.vue'
import LevelsListEdit from './LevelsListEdit.vue'

export default {
  components: {
    LevelsListEdit,
    LevelsListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    const { proxy } = getCurrentInstance()

    const LEVEL_APP_STORE_MODULE_NAME = 'app-level'

    // Register module
    if (!store.hasModule(LEVEL_APP_STORE_MODULE_NAME)) store.registerModule(LEVEL_APP_STORE_MODULE_NAME, levelStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(LEVEL_APP_STORE_MODULE_NAME)) store.unregisterModule(LEVEL_APP_STORE_MODULE_NAME)
    })

    const isAddNewLevelSidebarActive = ref(false)
    const isEditLevelSidebarActive = ref(false)
    const item = ref(null)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const categoryOptions = [
      { label: 'AP/P', value: 'AP/P' },
      { label: 'PM/SPM', value: 'PM/SPM' },
      { label: 'Junior Staff', value: 'Junior Staff' },
      { label: 'External', value: 'External' },
    ]

    const types = ref([])

    onMounted(() => {
      proxy.$http.get('/questionnaires/types')
        .then(response => {
          types.value = response.data
        })
        .catch(error => console.error('Error fetching type names:', error))
    })

    const {
      fetchLevels,
      tableColumns,
      limit,
      page,
      totalLevels,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      sortOrder,
      refLevelListTable,
      refetchData,

      // UI
      resolveLevelRoleVariant,
      resolveLevelRoleIcon,
      resolveLevelStatusVariant,
      resolveDepartmentVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useLevelsList()

    const sortOrderSync = computed({
      get: () => sortOrder.value === 'desc',
      set: (newVal) => {
        sortOrder.value = newVal ? 'desc' : 'asc'
      }
    })

    return {
      // Sidebar
      isAddNewLevelSidebarActive,
      isEditLevelSidebarActive,
      fetchLevels,
      tableColumns,
      limit,
      page,
      totalLevels,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      sortOrderSync,
      refLevelListTable,
      refetchData,
      avatarText,
      resolveLevelRoleVariant,
      resolveLevelRoleIcon,
      resolveLevelStatusVariant,
      resolveDepartmentVariant,
      roleOptions,
      planOptions,
      statusOptions,
      categoryOptions,
      roleFilter,
      planFilter,
      statusFilter,
      item,
      types,
    }
  },
  methods: {
    editItem(item) {
      this.item = item
      this.isEditLevelSidebarActive = true
    },
    getTooltip(category, type) {
      if (this.types[category] && this.types[category][type]) {
        return this.types[category][type]
      }
      return type
    },
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.edit-button {
  color: $primary;
  cursor: pointer;
}
</style>
